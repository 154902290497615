<template>
  <div>
    <b-container style="padding-top: 15rem;">
      <b-row>
        <b-col></b-col>
        <b-col cols="6">
          <b-form-group
            label-align-sm="right"
            label-for="nested-street">
            <b-form-input v-model="loginForm.email"
                          size="md" placeholder="开发者账号"
                          aria-describedby="signup-email-feedback" style="margin-bottom: 25px">
            </b-form-input>
            <b-form-invalid-feedback id="signup-email-feedback">邮箱格式不正确</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-align-sm="right"
            label-for="nested-city">
            <b-form-input type="password" v-model="loginForm.password"
                          size="md" placeholder="开发者密码"
                          aria-describedby="signup-password-feedback">
            </b-form-input>
            <b-form-invalid-feedback id="signup-password-feedback">密码格式不正确</b-form-invalid-feedback>

          </b-form-group>
          <div style="text-align: right">
            <router-link :to="{ name: 'forgotPassword'}">
              <b-button size="sm" variant="link" class="ml-auto" style="color: #f39111">忘记密码
              </b-button>
            </router-link>
          </div>
          <div>
            <b-button :variant="getAcceptAll? 'warning': ''" class="mt-3" size="md" @click="handleSubmit"
                      style="display: block; margin: 0 auto">登录</b-button>
          </div>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <InfoModal ref="loginModal"></InfoModal>
  </div>

</template>

<script>

import { BButton, BForm, BFormGroup,BInputGroup,BInputGroupPrepend,BFormInput,BFormInvalidFeedback } from 'bootstrap-vue'
import {login} from "../../../util/APIUtils";

export default {
  name: "Login",
  components: {
    BButton,BForm,BFormGroup,BInputGroup,BInputGroupPrepend, BFormInput,BFormInvalidFeedback
  },
  data() {
    return {
      reg: {
        email:/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
        name: /^[\u3040-\u309F\u30A0-\u30FF\u4e00-\u9fa5a-zA-Z0-9]{1,15}$/,
        // password:/^[\w+!@#$%^&*.]{6,18}$/,
        password:/^[\d\D]{6,18}$/
      },
      loginForm: {
        email: null,
        password: null,
      },
      redirect: '/',

    }
  },
  computed: {
    emailState() {
      if (!this.loginForm.email) {
        return null
      }

      return this.reg.email.test(this.loginForm.email)
    },
    pwdState() {
      if (!this.loginForm.password) {
        return null
      }
      return this.reg.password.test(this.loginForm.password)
    },

    getAcceptAll() {
      return this.emailState && this.pwdState;
    }
  },
  methods: {
    handleSubmit() {
      if(this.getAcceptAll){
        this.loginHandler();
      }
    },
    async loginHandler() {
      this.$store.dispatch('login', this.loginForm).then((res) => {
        if(res.code===0){
          this.$router.push({path: this.redirect || ''});
        }else{
          this.$refs.loginModal.showModal('密码不对');
        }
      });
    },

  },
  mounted() {

  }
}
</script>

<style scoped>


</style>
